.admin-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    font-family: 'Poppins', sans-serif;
    min-height: 600px;
    max-width: 1000px;
    width: 90%;
    overflow-y: hidden;
}

.admin-heading {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.admin-filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.admin-filter-container select {
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 1rem;
    color: #333;
    outline: none;
    transition: all 0.3s ease;
}

.admin-filter-container select:hover {
    border-color: #007bff;
}

.admin-export-button, .admin-logout-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 10px;
    font-weight: 500;
    margin-top: 10px;
}

.admin-export-button:hover, .admin-logout-button:hover {
    background-color: #0056b3;
}

.admin-booking-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.admin-booking-table thead {
    background-color: #007bff;
    color: #fff;
    font-weight: 600;
}

.admin-booking-table th, .admin-booking-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.admin-error-message {
    color: #ff4d4d;
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
}

.admin-login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    font-family: 'Poppins', sans-serif;
}

.admin-login-form .admin-form-group {
    margin-bottom: 15px;
}

.admin-login-form label {
    display: block;
    margin-bottom: 5px;
}

.admin-login-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
}

.admin-login-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
}

.admin-login-button:hover {
    background-color: #0056b3;
}

.admin-delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 6px;
}

.admin-delete-button:hover {
    background-color: #c82333;
}

@media (max-width: 768px) {
    .admin-filter-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .admin-export-button, .admin-logout-button {
        width: 100%;
        margin-top: 10px;
    }

    .admin-booking-table th, .admin-booking-table td {
        font-size: 14px;
        padding: 8px;
    }
    
    .admin-container {
        padding: 0px;
        overflow-y: scroll;
    }
}
/* Container */
.premium-login-container {
    background-color: #f9f9f9;
    width: 400px;
    padding: 30px;
    margin: 100px auto;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Header */
  .premium-login-container h2 {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
    color: #333;
    text-align: center;
    text-transform: uppercase;
  }
  
  /* Form */
  .premium-login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Form Group */
  .premium-login-form-group {
    display: flex;
    flex-direction: column;
  }
  
  /* Label */
  .premium-login-form-group label {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
  }
  
  /* Input Fields */
  .premium-login-form-group input {
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #ddd;
    transition: all 0.3s ease;
  }
  
  .premium-login-form-group input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  /* Error Message */
  .premium-login-error-message {
    color: #e74c3c;
    font-size: 14px;
    text-align: center;
  }
  
  /* Button */
  .premium-login-button {
    background-color: #007bff;
    color: #fff;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .premium-login-button:hover {
    background-color: #0056b3;
  }
  
.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1200px; /* Full height of the viewport */
  max-height: 750px;
  background-color: #002925;
  flex-direction: column; /* Ensure the title and slider are in a column */
  overflow: hidden;
}

h2 {
  margin-bottom: 20px; 
  text-align: start; 
  color: white;
}

.slider-container-1 {
  width: 100%;
}

.slider-container p {
  padding: 20px;
  color: white;
  margin: 0;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.slick-center .slide-item img {
  transform: scale(2.2);
  transition: transform 0.3s ease-in-out;
}

.slick-slide:not(.slick-center) .slide-item img {
  margin: 50px;
  transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.slide-item img {
  width: 100%;
  height: 450px;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .slider-container {
    height: 70vh; 
  }
  .slide-item img {
    width: 100%;
    height: 100px;
  }
}

/* BookingForm.css */

.booking-form {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
  
  .form-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
  }
  
  .form-icon {
    position: absolute;
    left: 10px;
    color: #888;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px 10px 10px 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  .form-group input:focus {
    border-color: #6a11cb;
    outline: none;
  }
  
  .custom-submit-button {
    width: 100%;
    padding: 10px;
    background: #002925;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .custom-submit-button:hover {
    background:  #014e47;
  }
  .custom-back-button{
    width: 100%;
    padding: 10px;
    background: #6a11cb;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s;
  }
  .custom-back-button:hover {
    background: #014e47;
  }
  @media screen and (max-width: 768px) {
    .booking-form {
      padding: 20px;
      width: 90%;
    }
  
    .form-title {
      font-size: 20px;
    }
  
    .form-group input {
      padding: 10px 10px 10px 35px;
      font-size: 14px;
    }
  
    .custom-submit-button {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .booking-form {
      padding: 15px;
    }
  
    .form-title {
      font-size: 18px;
    }
  
    .form-group input {
      padding: 8px 8px 8px 30px;
      font-size: 14px;
    }
  
    .custom-submit-button {
      font-size: 14px;
    }
  }
  
.footer {
	background-color: #002925;
	padding: 20px 0;
  }
  
  .footer__container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	margin: 0 auto;
  }
  
  .footer-contact {
	flex: 1;
	text-align: start;
	margin-left: 30px;
	width: 33.33%;
  }
  
  .footer-contact h2 {
	margin-bottom: 10px;
	color: #C2AB80;
  }
  
  .footer-contact p {
	margin-bottom: 5px;
	color: white;
  }
  
  .footer__button {
	margin-top: 10px;
	padding: 10px 20px;
	background-color: #1ac9b1;
	color: #ffffff;
	border: none;
	cursor: pointer;
  }
  
  .footer__button:hover {
	background-color:  #014e47;
  }
  
  .footer__social-links {
	display: flex;
	gap: 10px;
	margin-top: 10px;
	font-size: 60px;
	
  }
  
  .footer__social-links a {
	text-decoration: none;
	color: inherit;
	font-size: 40px; /* Adjust size as needed */
	margin-bottom: 30px;
  }
  
  .footer_img {
	width: 15.33%;
	display: flex;
	justify-content: right;
  }
  
  .footer_img img {
	width: 140%;
  }
  
  .footer__copyright {
	width: 100%;
	text-align: start;
	margin-left: 30px;
  }
  
  @media screen and (max-width: 768px) {
	.footer__container {
	  flex-direction: column;
	}
	.footer-contact {
	  width: 90%;
	}
	.footer_img {
	  display: none;
	}
	.footer__copyright {
	  width: 100%;
	  text-align: start;
	  margin-left: 10px;
	  font-size: 13px;
	}
	.footer {
	  background-color: #154466;
	  padding: 20px 0;
	}
  }
  .footer-link {
	cursor: pointer;
	color: #fff;
	text-decoration: none;
  }
  
  .footer-link:hover {
	color: #8e21e7;
  }
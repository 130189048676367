@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:ital,wght@0,400;1,100&display=swap');

@font-face {
  font-family: 'Somar';
  src: url('/public/Font/Somar-Regular.ttf') format('truetype');
}
.hero{
    background-image: url("/public/img/hero.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 95vh;
    width: 100%;
    position: relative;
    
   top: -100px;
   
  display: flex;
  flex-wrap: nowrap;
}

.herodata{
    padding: 0px 33px ;
    width: 60%;
    height: 100%;
    color: white;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.herotxt{
width: 50%;
padding-top: 270px;
 
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

   padding-left: 120px;
   text-align: start;
   
 
}
.herotxt h2 {
  color: #ffffff; /* Front color (white) */
  text-shadow:
    4px 4px 2px rgba(36, 35, 35, 0.6), /* Grey outline */
    0 0 0 rgba(0, 0, 0, 0); /* No additional shadow (to make the outline sharp) */
}

.herotxt p{
  margin: 0px;
  color: white;
  text-shadow:
  4px 4px 2px rgba(36, 35, 35, 0.6), /* Grey outline */
  0 0 0 rgba(0, 0, 0, 0);
}
.heroimg img{
  width: 350px;
  height: 250px;
  
  animation: float 3s ease-in-out infinite;

 
 

}
@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
.button-conatainer{
  width: 100%;
}
.btn-donate {

margin-top: 60px;
    --clr-font-main: hsla(0 0% 20% / 100);
    --btn-bg-1: #8d44db;
    --btn-bg-2: #6a11cb;
    --btn-bg-color: hsla(360 100% 100% / 1);
    --radii: 0.5em;
    cursor: pointer;
    padding: 0.9em 1.4em;
    min-width: 120px;
    min-height: 44px;
    font-size: var(--size, 1rem);
    font-family: "Segoe UI", system-ui, sans-serif;
    font-weight: 500;
    transition: 0.8s;
    background-size: 280% auto;
    background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%);
    border: none;
    border-radius: var(--radii);
    color: var(--btn-bg-color);
    box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35);
  }
  
  .btn-donate:hover {
    background-position: right top;
  }
  
  .btn-donate:is(:focus, :focus-visible, :active) {
    outline: none;
    box-shadow: 0 0 0 3px var(--btn-bg-color), 0 0 0 6px var(--btn-bg-2);
  }
  
  @media (prefers-reduced-motion: reduce) {
    .btn-donate {
      transition: linear;
    }
  }
  

@media screen and (max-width: 768px) {

    .hero{
        background-image: url("/public/img/hero.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        
        width: 100%;
       display: flex;
      height: 100vh;
       flex-wrap: wrap;
  
       
    }
    .herotxt h2{
      color: white;
      line-height: 30px;
      margin: 0;
      margin-top: 50px;
    }
    
    .herodata{
      padding: 0px 0px ;
      width: 100%;
      color: white;
     margin-top: -80px;
      height: 50%;
  }
  .herotxt{
    width: 100%;
    height: 40%;
    display: flex;
 top: -50px;
  padding: 30px;
    justify-content: center;
    padding-top: 60px;
  }
  .heroimg img{
    width: 260px;
    max-width: 100%;
    height: 200px;
   
   
   
    
  }
  .btn-donate {

    margin-top: 10px;
       
      }
}



@media screen and (max-width: 468px) {
  .herodata{
    padding: 0px 0px ;
    width: 100%;
    color: white;
   margin-top: -180px;
    height: 50%;
}
}

.thank-you-container {
  height: 100vh;
  position: relative;
  overflow: hidden;
  top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  margin-bottom: -85px;
}

.thank-you-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/img/BG PRODUCTION (27).jpg'); /* replace with your image URL */
  background-size: cover;
  background-position: center;
  filter: blur(5px) brightness(0.7) contrast(1.2) saturate(1.5);
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  color: white; /* Ensure text is visible */
  text-align: center; /* Center-align the text */
  
  margin: 0;
}

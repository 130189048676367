@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:ital,wght@0,400;1,100&display=swap');

@font-face {
  font-family: 'Somar';
  src: url('/public/Font/Somar-Regular.ttf') format('truetype');
}
.mainabouts{
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;
   
    margin-top: 60px;
   
}
.abouts{
    display: flex;
flex-direction: row;
width: 100%;
height: 450px;
text-align: start;


}
.mainabouts h2{
    text-align: start;
    padding-left: 10px;
    
}

.textabout{
    width: 55%;
    height: 450px;
    text-align: start;
    padding: 15px;
}
.aboutimg{
    width: 45%;
    height: 450px;

}
.aboutimg img{
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 768px) {
    .mainabouts{
        width: 100%;
        height: 1300px;
        display: flex;
        flex-direction: column;
    }
    .abouts{
        display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 900px;
    text-align: start;
    
    
    }
    .textabout{
        width: 100%;
        height: 450px;
        
        text-align: start;
        padding: 15px;
    }
    .aboutimg{
        width: 100%;
        height: 450px;

    }
   
}
@media screen and (max-width: 468px) {
    .mainabouts{
        width: 100%;
        height: 1100px;
        display: flex;
        flex-direction: column;
    }

    .textabout{
        width: 100%;
        height: 660px;
        
        text-align: start;
        padding: 15px;
    }
    .aboutimg{
        width: 100%;
        height: 450px;
 
    }
    
}
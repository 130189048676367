.room-conatiner{
    width: 100%;
    height: 50px;
    position: relative;
    top: -160px;
}
.room-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.room-card img{
    width: 23%;
    /* height: 180px; */
}

@media screen and ( max-width:1040px) {
    .room-conatiner{
        width: 100%;
        height: 250px;
        position: relative;
        top: -160px;
    }
}
@media screen and ( max-width:764px) {
    .room-conatiner{
        width: 100%;
        height: 250px;
        position: initial;
        top: 0px;
        margin-top:30px ;
        
    }
    .room-card img{
        width: 29%;
        /* height: 180px; */
    }
}
@media screen and ( max-width:503px) {
    .room-conatiner{
        width: 100%;
        height: 1100px;
        position: initial;
        top: 0px;
        margin-bottom: 30px;
    }
    .room-card img{
        width: 80%;
        height: 20%;
        
    }
}
@media screen and ( max-width:404px) {
    .room-conatiner{
        width: 100%;
        height: 900px;
        position: initial;
        top: 0px;
        margin-bottom: 30px;
    }
    .room-card img{
        width: 80%;
        height: 20%;
        
    }
}
@media screen and ( max-width:425px) {
    .room-conatiner{
        width: 100%;
        height: 890px;
        position: initial;
        top: 0px;
        margin-bottom: 30px;
    }
    .room-card img{
        width: 80%;
        height: 20%;
        
    }
}
@media screen and ( max-width:324px) {
    .room-conatiner{
        width: 100%;
        height: 750px;
        position: initial;
        top: 0px;
        margin-bottom: 30px;
    }
    .room-card img{
        width: 80%;
        height: 20%;
        
    }
}
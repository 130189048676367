.review-slider-container{
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 40px;
  height: 600px;
}

@media screen and (max-width: 768px) {
  .review-slider-container{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 10px;
    height: 600px;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:ital,wght@0,400;1,100&display=swap');

.navbar {
    height: 65px;
    width: 100%;
    background: #ffffff00;
    color: #fff;
    top: 0;
    bottom: 0;
    position: sticky;
    z-index: 961;
}

nav.navbar.active {
    background-color: #002925 !important;
    transition: 0.5s ease-in;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-container .navbar-logo.show {
    opacity: 1;
}

.navbar-logo {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    filter: brightness(0) invert(1);
}

.navbar-logo img {
    height: 50px;
    max-width: 205px; 
    width: 100%;
    margin-left: 60px;
}

.menu-icon {
    display: none;
    cursor: pointer;
    font-size: 30px;
    margin-right: 30px;
    color: #ffffff;
    transition: transform 0.3s ease-in-out;
}

.menu-icon.open {
    transform: rotate(90deg);
}

.nav-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 10px;
    font-size: 15px;
    font-weight: 800;
}

.nav-item {
    margin-right: 25px;
    margin-top: 2%;
}

.nav-link {
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.nav-link:hover {
    color: #8e21e7;
    transition: ease-in-out 0.5s;
}

.nav-item {
    position: relative;
}

@media screen and (max-width: 768px) {
    .menu-icon {
        display: block;
        justify-content: right;
    }

    .navbar-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 80px;
        left: -100%;
        width: 94.5%;
        opacity: 0;
        transition: all 0.5s ease;
        background-color: rgb(18,69,102);
    }

    .nav-menu.active {
        left: 0;
        opacity: 1;
        z-index: 1;
    }

    .nav-item {
        margin-bottom: 10px;
    }

    .nav-link {
        color: #fff;
        text-decoration: none;
    }

    .navbar-logo img {
        height: 60px;
        width: 220px;
        padding: 1%;
        margin-left: 0px;
    }
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 320px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-item {
    padding: 5px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    color: #00d9ff;
    font-weight: 400;
    text-align: start;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}

.bn632-hover {
    width: 160px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    transition: all .4s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bn632-hover:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out;
}

.bn632-hover:focus {
    outline: none;
}

.bn632-hover.bn27 {
    background-image: linear-gradient(to right, #ed6ea0, #ec8c69, #f7186a, #fbb03b);
    box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}

.decor {
    text-decoration: none !important;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    margin-right: 30px;
}
